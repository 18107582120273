<template>
  <div class="normal-add-dialog">
    <el-dialog
      class=""
      :title="title"
      :visible.sync="showMemberCardDialog"
      top="3%"
      width="45%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeMemberCardDialog"
    >
      <!--  -->
      <div>
        <el-form
          ref="formName"
          :model="formData"
          :rules="rulesFormData"
          label-width="85px"
          size="mini"
        >
          <div class="normal-add-dialog-content" style="margin: 0 0 20px 0;">
            <div class="tabs-switch-content">
              <el-row :gutter="20">
                <el-col :span="10">
                  <DFUpload
                    ref="DFUpload"
                    @success="saveCard"
                    @remove="onRemoveImage"
                    @error="onUploadError"
                  >
                  </DFUpload>
                </el-col>
                <el-col class="tabs-col" :span="10">
                  <div class="tabs-col-item">
                    <el-form-item label="卡名称" prop="CardName">
                      <el-input
                        v-model="formData.CardName"
                        placeholder="请输入卡名称"
                      >
                      </el-input>
                    </el-form-item>

                    <el-form-item label="卡属性" prop="NatureNo">
                      <el-select
                        class="df-select"
                        v-model="formData.NatureNo"
                        placeholder="请选择卡属性"
                      >
                        <el-option
                          v-for="item in natureSelector"
                          :key="item.NatureNo"
                          :label="item.NatureName"
                          :value="item.NatureNo"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="卡名称" prop="CardName">
                <el-input
                  v-model="formData.CardName"
                  placeholder="请输入卡名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="卡属性" prop="NatureNo">
                <el-select
                  class="df-select"
                  v-model="formData.NatureNo"
                  placeholder="请选择卡属性"
                >
                  <el-option
                    v-for="item in natureSelector"
                    :key="item.NatureNo"
                    :label="item.NatureName"
                    :value="item.NatureNo"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row> -->
          <!--  -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="卡分类">
                <el-select
                  class="df-select"
                  v-model="formData.KindGuid"
                  clearable
                  placeholder="请选择卡分类"
                >
                  <el-option
                    v-for="item in kindSelector"
                    :key="item.KindGuid"
                    :label="item.KindName"
                    :value="item.KindGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="售价">
                <el-input v-model="formData.Price" placeholder="请输入售价">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--  -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="提成方式">
                <el-select
                  class="df-select"
                  v-model="formData.PerforRoyaClass"
                  clearable
                  placeholder=""
                >
                  <el-option
                    v-for="item in perforRoyaClassSelector"
                    :key="item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效日期">
                <el-input
                  v-model="formData.DateLimit"
                  placeholder="请输入有效日期"
                >
                  <el-select
                    v-model="formData.DateUnits"
                    slot="append"
                    placeholder="单位"
                    clearable
                    style="width: 80px"
                  >
                    <el-option
                      v-for="(item, index) in dateUnitsSelector"
                      :key="item.Value"
                      :label="item.Name"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!--  -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="卡过期前">
                <div>
                  <el-input
                    v-model="formData.OverdueSms"
                    placeholder="请输入天数"
                  >
                    <template slot="append"> 天提醒 </template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="小程序端会员卡介绍">
                <div>
                  <el-button type="primary" size="mini" @click="onOpenDrawer"
                    >编辑</el-button
                  >
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <!--  -->

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="办卡协议">
                <el-select
                  class="df-select"
                  v-model="formData.ContractGuid"
                  clearable
                  placeholder="可选"
                >
                  <el-option
                    v-for="item in contractSelector"
                    :key="item.ContractGuid"
                    :label="item.Name"
                    :value="item.ContractGuid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="备注">
                <div style="display: flex">
                  <el-input
                    v-model="formData.CardNote"
                    type="textarea"
                    :rows="3"
                    clearable
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <div class="normal-add-dialog-content">
            <div class="tabs-switch-content">
              <el-checkbox
                v-model="formData.DonaItem"
                :true-label="1"
                :false-label="0"
                >允许赠送项目</el-checkbox
              >
            </div>
          </div> -->

          <!-- start 各卡类型额外信息 -->
          <div
            v-show="formData.NatureNo != ''"
            class="normal-add-dialog-content"
          >
            <!--  -->
            <div class="tabs-switch-content">
              <div
                v-show="
                  formData.NatureNo == '0001' || formData.NatureNo == '0003'
                "
              >
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="充值下限">
                      <div>
                        <el-input
                          v-model="formData.KeepLimit"
                          size="mini"
                          placeholder="请输入充值下限"
                        >
                          <template slot="append"> 元 </template>
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="转账下限">
                      <div>
                        <el-input
                          v-model="formData.TranLimit"
                          size="mini"
                          placeholder="请输入转账下限"
                        >
                          <template slot="append"> 元 </template>
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!--  -->
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="卡余额低于">
                      <div>
                        <el-input
                          v-model="formData.UnderMoney"
                          size="mini"
                          placeholder="请输入金额"
                        >
                          <template slot="append"> 元，不可消费 </template>
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="充值">
                      <div style="display: flex">
                        <el-input
                          v-model="formData.KeepUpStart"
                          size="mini"
                          placeholder="开始"
                        >
                        </el-input>
                        <span style="margin: 0 10px">至</span>
                        <el-input
                          v-model="formData.KeepUpEnd"
                          size="mini"
                          placeholder="结束"
                        >
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!--  -->
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      class="upgrade"
                      label="卡自动升级"
                      prop="OnlyUpgrade"
                    >
                      <div>
                        <el-select
                          class="df-select"
                          v-model="formData.OnlyUpgrade"
                          slot="append"
                          placeholder="请选择卡自动升级"
                        >
                          <el-option
                            v-for="item in upgradeModeSelector"
                            :key="item.Value"
                            :label="item.Name"
                            :value="item.Value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="其他">
                      <el-checkbox
                        v-model="formData.KeepMark"
                        :true-label="1"
                        :false-label="0"
                        >允许充值</el-checkbox
                      >
                      <el-checkbox
                        v-model="formData.TranMark"
                        :true-label="1"
                        :false-label="0"
                        >允许转账</el-checkbox
                      >
                      <el-checkbox
                        v-model="formData.PriceToKeep"
                        :true-label="1"
                        :false-label="0"
                        >开卡金额转充值金额</el-checkbox
                      >
                      <el-checkbox
                        v-model="formData.MonthlySms"
                        :true-label="1"
                        :false-label="0"
                        >扣短信月租</el-checkbox
                      >
                      <el-checkbox
                        v-model="formData.DonaItem"
                        :true-label="1"
                        :false-label="0"
                        >允许赠送项目</el-checkbox
                      >
                      <el-checkbox
                        v-model="formData.NoPayForPackCard"
                        :true-label="1"
                        :false-label="0"
                        >卡金不可购买疗程卡</el-checkbox
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div v-show="formData.NatureNo == '0002'">
                <el-row :gutter="20">
                  <el-col>
                    <div>
                      <el-radio v-model="formData.PackMode" :label="0"
                        >指定疗程项目</el-radio
                      >
                      <el-radio v-model="formData.PackMode" :label="1"
                        >预设部分疗程项目</el-radio
                      >
                      <el-radio v-model="formData.PackMode" :label="2"
                        >开单时灵活自选疗程项目</el-radio
                      >
                    </div>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="其他" label-width="50px">
                      <el-checkbox
                        v-model="formData.DonaItem"
                        :true-label="1"
                        :false-label="0"
                        >允许赠送项目</el-checkbox
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>

              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="连锁不通用" label-width="90px">
                    <el-switch
                      v-model="formData.NoCrossStore"
                      :active-value="1"
                      :inactive-value="0"
                    >
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- end 各卡类型额外信息 -->
        </el-form>
      </div>
      <!--  -->
      <div slot="footer">
        <el-button size="mini" @click="closeMemberCardDialog">
          取 消
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="submitDisabled"
          @click="onUploadPicture"
        >
          确 定
        </el-button>
      </div>
      <!--  -->
    </el-dialog>

    <!-- 编辑备注抽屉 -->
    <!-- <el-drawer
      title="编辑备注信息"
      :visible.sync="showDrawer"
      :wrapperClosable="false"
      direction="rtl"
      size="50%"
    >
      <quillEditor
        id="editor"
        ref="myQuillEditor"
        :options="options"
        v-model="formData.CardNote"
        @ready="onEditorReady($event)"
        @change="onEditorChange"
      ></quillEditor>
    </el-drawer> -->

    <DfQuilEditorDrawer
      ref="DfQuilEditorDrawer"
      @onBlurText="onBlurEdit"
    ></DfQuilEditorDrawer>
  </div>
</template>

<script>
import api from "@/api/card.js";

import { baseURL } from "@/utils/axios.js";

export default {
  data() {
    return {
      title: "添加会员卡",
      formData: {
        CardGuid: "",
        CardName: "",
        CardNote: "",
        ContractGuid: "",
        NatureNo: "",
        KindGuid: "",
        Price: "",
        PerforRoyaClass: 0,
        DateLimit: "",
        DateUnits: 0,
        OverdueSms: "",
        KeepMark: 1,
        KeepLimit: "",
        TranMark: 0,
        TranLimit: "",
        UnderMoney: "",
        KeepUpStart: "",
        KeepUpEnd: "",
        OnlyUpgrade: 0,
        MonthlySms: 0,
        PriceToKeep: 1,
        PackMode: 0,
        RichDetail: "",
        CoverImage: "",
        CoverImageUrl: "",
      },
      rulesFormData: {
        CardName: [{ required: true, message: "请输入卡名", trigger: "blur" }],

        NatureNo: [
          { required: true, message: "请选择卡属性", trigger: "change" },
        ],

        // KindGuid: [
        //   { required: true, message: "请选择卡分类", trigger: "change" },
        // ],

        OnlyUpgrade: [
          { required: true, message: "请选择自动升级", trigger: "change" },
        ],
      },
      tabsData: [
        { id: "0001", name: "储值卡" },
        { id: "0002", name: "疗程卡" },
      ],
      tabItemActivate: 0,
      dateUnitsSelector: [],
      kindSelector: [],
      natureSelector: [],
      perforRoyaClassSelector: [],
      upgradeModeSelector: [],
      showMemberCardDialog: false,
      showDrawer: false,
      submitDisabled: false,
    };
  },

  watch: {
    formData: {
      deep: true,
      handler(newVal) {
        let fileList = this.$refs.DFUpload ? this.$refs.DFUpload.fileList : [];
        if (
          fileList.length != 0 &&
          fileList[0].url == this.formData.CoverImageUrl
        ) {
          return;
        }

        let pictureArray = this.formData.CoverImageUrl
          ? [{ url: this.formData.CoverImageUrl }]
          : [];

        this.$nextTick(() => {
          // this.$refs.DFUpload.initImageList(pictureArray)
          pictureArray ? this.$refs.DFUpload.initImageList(pictureArray) : null;
        });
      },
    },
  },

  mounted() {},

  methods: {
    async initComponentData() {
      try {
        let { data } = await api.initSaveCard({
          card_guid: this.formData.CardGuid,
        });
        let {
          dateUnitsSelector,
          editData,
          kindSelector,
          natureSelector,
          perforRoyaClassSelector,
          upgradeModeSelector,
          contractSelector
        } = data;
        this.dateUnitsSelector = dateUnitsSelector;
        this.kindSelector = kindSelector;
        this.natureSelector = natureSelector;
        this.perforRoyaClassSelector = perforRoyaClassSelector;
        this.upgradeModeSelector = upgradeModeSelector;
        this.contractSelector = contractSelector
        // console.log(data);
        if (this.formData.CardGuid) {
          this.formData = editData;
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    async onUploadPicture() {
      // console.log(this.formData);
      if (this.formData.CoverImage) {
        return this.saveCard({ data: this.formData.CoverImage });
      }

      let token = await window.sessionStorage.getItem("token");
      this.$refs.DFUpload.submitUpload(
        baseURL + "/card/upload_cover_picture?acctoken=" + token
      );
    },

    async saveCard(event) {
      if (this.submitDisabled) {
        return;
      }

      let imageUrl = (await event) ? event.data : "";
      this.formData.CoverImage = await imageUrl;

      this.formData.Price =
        this.formData.Price === "" ? 0 : this.formData.Price;
      this.formData.DateLimit =
        this.formData.DateLimit === "" ? 0 : this.formData.DateLimit;
      this.formData.DateUnits =
        this.formData.DateUnits === "" ? 0 : this.formData.DateUnits;
      this.formData.OverdueSms =
        this.formData.OverdueSms === "" ? 0 : this.formData.OverdueSms;
      this.formData.KeepLimit =
        this.formData.KeepLimit === "" ? 0 : this.formData.KeepLimit;
      this.formData.TranLimit =
        this.formData.TranLimit === "" ? 0 : this.formData.TranLimit;
      this.formData.UnderMoney =
        this.formData.UnderMoney === "" ? 0 : this.formData.UnderMoney;
      this.formData.KeepUpStart =
        this.formData.KeepUpStart === "" ? 0 : this.formData.KeepUpStart;
      this.formData.KeepUpEnd =
        this.formData.KeepUpEnd === "" ? 0 : this.formData.KeepUpEnd;

      // console.log(this.formData.CardNote);

      this.submitDisabled = true;
      try {
        let { data } = await api.saveCard(this.formData);
        await this.closeMemberCardDialog();
        this.$message({
          message: "成功",
          type: "success",
        });
        await this.$emit("refreshData");
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    onRemoveImage() {
      this.$confirm(`是否要删除图片?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await api.deleteCoverPricture({
            card_guid: this.formData.CardGuid,
          });
          if (errcode == 0) {
            this.$message.success("图片删除成功");
            this.initComponentData();
          }
        } catch (error) {
          console.log(error);
        }
      });
    },

    onUploadError(event) {
      console.log(event);
    },

    onSubmitData() {
      this.$refs["formName"].validate(async (valid) => {
        if (valid) {
          // let token = await window.sessionStorage.getItem("token");
          // this.$refs.DFUpload.submitUpload(
          //   baseURL + "/member/save_member_picture?acctoken=" + token
          // );

          this.saveCard();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    openMemberCardDialog(cardGuid, typeText) {
      this.formData.CardGuid = cardGuid ? cardGuid : "";
      this.title = typeText + "会员卡";
      this.initComponentData();
      this.showMemberCardDialog = true;
    },

    closeMemberCardDialog() {
      this.$refs["formName"].resetFields();
      this.showMemberCardDialog = false;
      this.clearData();
    },

    handleClose() {
      this.closeMemberCardDialog();
    },

    onTabsItem(index) {
      this.tabItemActivate = index;
    },

    clearData() {
      this.formData = {
        CardGuid: "",
        CardName: "",
        ContractGuid: "",
        NatureNo: "",
        KindGuid: "",
        Price: "",
        PerforRoyaClass: 0,
        DateLimit: "",
        DateUnits: "",
        OverdueSms: "",
        CardNote: "",
        KeepMark: 1,
        KeepLimit: "",
        TranMark: 0,
        TranLimit: "",
        UnderMoney: "",
        KeepUpStart: "",
        KeepUpEnd: "",
        OnlyUpgrade: 0,
        MonthlySms: 0,
        PriceToKeep: 1,
        PackMode: 0,
      };
    },

    onOpenDrawer() {
      this.$refs.DfQuilEditorDrawer.onShowDrawer(this.formData.RichDetail);
    },

    onBlurEdit(event) {
      this.formData.RichDetail = event;
    },

    // 准备富文本编辑器
    onEditorReady(event) {
      Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
      // console.log(event);
      // event.insertEmbed(
      //   10,
      //   "image",
      //   "http://statics.difengsoft.com/store-web/no-pictrue-rectangle.png"
      // );

      setTimeout(() => {
        event.blur();
      }, 100);
    },

    onEditorChange(e) {
      console.log(e);
      // let style = '<img style="" src'
      // let str = e.html.replace('<img src', '')
    },
  },
};
</script>

<style lang="less" scoped>
.normal-add-dialog {
  .upgrade {
    display: flex !important;
    ::v-deep .el-form-item__label {
      min-width: 100px !important;
      width: auto !important;
    }
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .df-select {
    width: 100%;
  }

  .normal-add-dialog-content {
    border: 1px solid #dcdfe6;
    border-radius: 3px;
    margin-top: 20px;
    .card-content-tabs {
      display: flex;
      border-bottom: 1px solid #dcdfe6;
      .card-content-tabs-item {
        padding: 10px 0;
        margin: 0 20px;
      }
      .card-content-tabs-item-activate {
        border-bottom: 2px solid #409eff;
      }
    }
    .tabs-switch-content {
      padding: 20px;
    }
    .tabs-col {
      height: 150px;

      .tabs-col-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .quill-editor {
    width: 100%;
    height: 60%;
    // height: 60px;
    // ::v-deep .ql-container {
    //   border-radius: 4px;
    //   border-color: #dcdfe6;
    //   .ql-editor {
    //     padding: 0 15px;
    //   }
    // }
  }
}
</style>
